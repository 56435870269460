import "../scss/app.scss";
import Vue from "vue";
// import bootstrap from "bootstrap";

import { Modal, Toast, Popover } from "bootstrap";
// new Modal();
// const Modal = require("bootstrap/js/dist/modal");
// var myModal = new Modal(document.getElementById("menuModal"));

const app = new Vue({
  el: "#app",
  data: {
    searchMode: false,
    navMode: false,
  },
  mounted() {},
});
